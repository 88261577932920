:root {
    --shadow-color: 200deg 37% 30%;
    --shadow-elevation-low:
      0.3px 0.7px 1px hsl(var(--shadow-color) / 0.1),
      0.5px 1.1px 1.6px -0.7px hsl(var(--shadow-color) / 0.15),
      1px 2.1px 3px -1.4px hsl(var(--shadow-color) / 0.2);
    --shadow-elevation-medium:
      0.3px 0.7px 1px hsl(var(--shadow-color) / 0.1),
      1.1px 2.2px 3.2px -0.5px hsl(var(--shadow-color) / 0.14),
      2.3px 4.8px 6.9px -0.9px hsl(var(--shadow-color) / 0.18),
      5.1px 10.5px 15.1px -1.4px hsl(var(--shadow-color) / 0.22);
    --shadow-elevation-high:
      0.3px 0.7px 1px hsl(var(--shadow-color) / 0.09),
      1.5px 3.1px 4.4px -0.2px hsl(var(--shadow-color) / 0.11),
      2.6px 5.4px 7.7px -0.4px hsl(var(--shadow-color) / 0.13),
      4px 8.1px 11.7px -0.6px hsl(var(--shadow-color) / 0.15),
      5.8px 11.9px 17.1px -0.8px hsl(var(--shadow-color) / 0.17),
      8.5px 17.4px 25px -1px hsl(var(--shadow-color) / 0.19),
      12.3px 25.2px 36.2px -1.2px hsl(var(--shadow-color) / 0.21),
      17.5px 36px 51.6px -1.4px hsl(var(--shadow-color) / 0.23);
  }

  .starContainerLow {
    border-radius: 9px;
    box-shadow: var(--shadow-elevation-low);
    transition: box-shadow 0.3s ease-in-out;
  } 


  .starContainerMedium {
    border-radius: 9px;
    box-shadow: var(--shadow-elevation-medium);
    transition: box-shadow 0.3s ease-in-out;
  } 



  .starContainerHigh {
    border-radius: 9px;
    box-shadow: var(--shadow-elevation-high);
    transition: box-shadow 0.3s ease-in-out;
  } 


/* Add this to your CSS file (e.g., styles.css) */

/* Default star styles */
.starIcon {
    width: 26px;
    height: auto;
    display: block;
    transition: transform 0.2s ease-in-out, width 0.2s ease-in-out;
  }
  
  /* Hover effect for unselected stars */
  .starIcon:hover {
    transform: scale(1.4); /* Increase size on hover */
  }
  
  /* Larger size for selected stars */
  .selectedStar {
    width: 38px; /* Larger size when selected */
  }
  
  /* Disable hover effect for selected stars */
  .selectedStar.noHover:hover {
    transform: scale(1); /* Disable hover scale effect when selected */
  }