:root {
  --shadow-color: 194deg 20% 61%;
  --shadow-elevation-low: 0px 0.2px 0.2px hsl(var(--shadow-color) / 0.34),
    0.1px 0.3px 0.4px -1.2px hsl(var(--shadow-color) / 0.34),
    0.1px 0.7px 0.8px -2.5px hsl(var(--shadow-color) / 0.34);
  --shadow-elevation-medium: 0px 0.2px 0.2px hsl(var(--shadow-color) / 0.36),
    0.1px 0.6px 0.7px -0.8px hsl(var(--shadow-color) / 0.36),
    0.3px 1.5px 1.7px -1.7px hsl(var(--shadow-color) / 0.36),
    0.7px 3.5px 4px -2.5px hsl(var(--shadow-color) / 0.36);
  --shadow-elevation-high: 0px 0.2px 0.2px hsl(var(--shadow-color) / 0.34),
    0.2px 1px 1.1px -0.4px hsl(var(--shadow-color) / 0.34),
    0.4px 1.9px 2.2px -0.7px hsl(var(--shadow-color) / 0.34),
    0.6px 3.2px 3.7px -1.1px hsl(var(--shadow-color) / 0.34),
    1px 5.1px 5.8px -1.4px hsl(var(--shadow-color) / 0.34),
    1.6px 7.9px 9.1px -1.8px hsl(var(--shadow-color) / 0.34),
    2.4px 12px 13.8px -2.1px hsl(var(--shadow-color) / 0.34),
    3.6px 17.7px 20.3px -2.5px hsl(var(--shadow-color) / 0.34);
}

.tableContainer {
  border-radius: 9px;
  box-shadow: var(--shadow-elevation-high);
  transition: box-shadow 0.3s ease-in-out;
}

.dashboardTable {
  border-radius: 9px;
  box-shadow: var(--shadow-elevation-medium);
  transition: box-shadow 0.3s ease-in-out;
}

.widgetContainer {
  border-radius: 8px;
  box-shadow: var(--shadow-elevation-medium);
  transition: box-shadow 0.3s ease-in-out;
}
