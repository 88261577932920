.listItemContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.listItemCheckbox {
  color: #80abc0;
  font-weight: 600;
  font-size: 27px;
  text-align: left;
  margin-left: 1rem;
}

.listItemComplete {
  color: #68a4bf;
  font-weight: 600;
  font-size: 27px;
  text-align: left;
  border-radius: 14px;
  margin-left: 1rem;
  padding: 0;
}

.listItemText {
  color: #48768c;
  font-weight: 500;
  font-size: 14.5px;
  text-align: left;
  margin-left: 8px;
  letter-spacing: -2%;
  line-height: 1.2;
  text-shadow:
    0.1px 0 0 currentColor,
    -0.1px 0 0 currentColor;
}

.setupLaterButton {
  display: flex;
  color: white;
  font-weight: 500;
  font-size: 12.75px;
  border: 0.75px solid #48768c;
  padding: 6px;
  border-radius: 6px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #48768c;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease; /* Smooth transition for both bg-color and shadow */

  /* Initial box-shadow for a subtle effect */
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

.setupLaterButton:hover {
  /* More vibrant and lighter background color on hover */
  background-color: #6497b1; /* Lighter variation of the original color */

  /* Slightly stronger shadow on hover */
  box-shadow:
    0 8px 12px rgba(0, 0, 0, 0.15),
    0 3px 6px rgba(0, 0, 0, 0.12);
}

.setupLaterButton:disabled,
.setupLaterButton.disabled {
  background-color: #b0c4de; /* Muted/greyish-blue for disabled state */
  color: #3d687c; /* Darker gray for text */
  border: 0.75px solid #a0a0a0; /* Lighter border */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  box-shadow: none; /* Remove shadow for disabled buttons */
  opacity: 0.7; /* Slight transparency */
}

/* To override hover effect for disabled buttons */
.setupLaterButton:disabled:hover,
.setupLaterButton.disabled:hover {
  background-color: #b0c4de; /* Maintain disabled background */
  box-shadow: none; /* No shadow on hover */
}

.addButton {
  width: 120px;
  display: flex;
  color: #48768c;
  font-weight: 600;
  font-size: 13px;
  border: 0.75px solid #48768c;
  padding: 6px;
  border-radius: 6px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: white;
  transition: box-shadow 0.3s ease; /* Adds smooth transition */

  /* Box shadow for subtle effect */
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
  white-space: nowrap;
}

.addButton:hover {
  /* Slightly stronger shadow on hover */
  box-shadow:
    0 8px 12px rgba(0, 0, 0, 0.15),
    0 3px 6px rgba(0, 0, 0, 0.12);
}
