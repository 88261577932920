.manage_notifications_card {
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  border: 1px solid lightgray;
}

.manage_notifications_header {
  margin-bottom: 5px;
  color: #48768c;
  font-weight: 600;
  font-size: 18.5px;
}

.notification_card_default {
  padding: 1rem;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(191, 218, 231, 0.05);
  border: 1px solid rgba(173, 216, 230, 0.7);
}

.notification_card {
  padding: 1rem;
  width: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(191, 218, 231, 0.05);
  border: 1px solid rgba(173, 216, 230, 0.7);
}

.field_name_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-width: 65%;
  max-width: 65%;
  border-radius: 8px;
  height: 35px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(173, 216, 230, 0.7);
}

.field_type_selector {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  background-color: transparent;
  font-size: 14px;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid rgba(173, 216, 230, 0.7);
}

.add_optional_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  min-width: 65%;
  max-width: 65%;
  border-radius: 8px;
  height: 40px;
  background-color: #f2f9ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(173, 216, 230, 0.7);
}

.field_name {
  margin-left: 1px;
  font-weight: 600;
  font-size: 17px;
  font-style: italic;
  color: #747c86;
}

.new_field_name {
  margin-left: 1px;
  font-weight: 600;
  font-size: 17px;
  font-style: italic;
  color: #747c86;
}

.optional_field_name {
  margin-left: 1px;
  font-weight: 600;
  font-size: 15px;
  font-style: italic;
  color: #747c86;
}

.field_icon {
  margin-right: 15px;
}

.field_title {
  margin-left: 10px;
  font-weight: 700;
  font-size: 14.5px;
  padding-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.field_select_title {
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
}

.default_field_icon {
  border: 1px solid #747c86;
  margin-top: 2px;
  width: auto;
  height: 24px;
  border-radius: 6px;
  background-color: #ffffff;
}

.popover_field_icon {
  border: 1px solid #747c86;
  margin-top: 2px;
  width: auto;
  height: 24px;
  border-radius: 6px;
  background-color: #ffffff;
  cursor: pointer;
}

.change_field_icon {
  border: 1px solid #747c86;
  margin-top: 2px;
  width: auto;
  height: 22px;
  border-radius: 6px;
  background-color: #ffffff;
  cursor: pointer;
  color: #48768c;
}

.new_field_icon {
  border: 1px solid #747c86;
  width: auto;
  height: 24px;
  border-radius: 6px;
  background-color: #ffffff;
  cursor: pointer;
}

.optional_field_icon {
  margin-top: 2px;
  width: auto;
  height: 23px;
  padding: 1px;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  margin-left: -2px;
}

.optional_field_title {
  margin-left: 5px;
  font-weight: 600;
  font-size: 13.5px;
  color: #747c86;
  align-items: center;
  align-self: center;
}

.custom_message_field {
  flex-direction: column;
  padding-top: 5px;

  padding-left: 5px;
  width: 55%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 290px;
  margin-top: 10px;
}

.field_title_date_time {
  margin-left: 5px;
  font-weight: 700;
  font-size: 15px;
  padding-top: 3px;
}

.field_name_container_timer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 55%;
  max-width: 55%;
  border-radius: 8px;
  height: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.custom-tooltip .MuiTooltip-tooltip {
  background-color: #ffffff;
}

.popover_save_button {
  align-items: center;
  font-size: 21px;
  border: 1px solid #747c86;
  border-radius: 5px;
  background-color: #ffffff;
  justify-content: flex-end;
}

.popover_cancel_button {
  align-items: center;
  font-size: 21px;
  border: 1px solid #747c86;
  border-radius: 5px;
  background-color: #ffffff;
  color: #8c4350;
  justify-content: flex-end;
}

.popover_edit_button {
  align-items: center;
  font-size: 21px;
  border: 1px solid #747c86;
  border-radius: 5px;
  background-color: #ffffff;
  color: #48768c;
  justify-content: flex-end;
}

.field_save_button {
  align-items: center;
  font-size: 17px;
  border-radius: 5px;
  background-color: #f2f9ff;
  color: #48b09b;
  margin-right: 0px;
}

.field_cancel_button {
  align-items: center;
  font-size: 21px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #8c4350;
}
.popover_back_button {
  align-items: center;
  font-size: 15px;
  background-color: #f3f9ff;
  color: #517d92;
  border: 1px solid #747c86;
  border-radius: 5px;
}

.icon_save_button {
  align-items: center;
  font-size: 17px;
  border-radius: 5px;
  background-color: transparent;
  color: #48b09b;
  margin-right: 0px;
}

.icon_cancel_button {
  align-items: center;
  font-size: 21px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #8c4350;
}

._2BBB9E {
  align-items: center;
  background-color: #219b83; /* Changed to color */
  color: #2bbb9e; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
}

._7589A2 {
  align-items: center;
  background-color: #667991; /* Changed to color */
  color: #7589a2; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
}

._a1848f {
  align-items: center;
  background-color: #8c6f7a; /* Changed to color */
  color: #a1848f; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
}

._8C4350 {
  align-items: center;
  background-color: #5c2933; /* Changed to color */
  color: #8c4350; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
}

._7589a2 {
  align-items: center;
  background-color: #5d6e84; /* Changed to color */
  color: #7589a2; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
}

._a1848f {
  align-items: center;
  background-color: #8b6976; /* Changed to color */
  color: #a1848f; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
}

._7d7371 {
  align-items: center;
  background-color: #565656; /* Darker shade of #7D7371, changed to color */
  color: #7d7371; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
}

._7f8487 {
  align-items: center;
  background-color: #565656; /* Darker shade of #7F8487, changed to color */
  color: #7f8487; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
}

._b5ad8e {
  align-items: center;
  background-color: #6b6b6b; /* Darker shade of #B5AD8E, changed to color */
  color: #b5ad8e; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
}

._827b76 {
  align-items: center;
  background-color: #5e5e5e; /* Darker shade of #827B76, changed to color */
  color: #827b76; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}

._a3847d {
  align-items: center;
  background-color: #635959; /* Darker shade of #A3847D, changed to color */
  color: #a3847d; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}

._858ba9 {
  align-items: center;
  background-color: #5e5e82; /* Darker shade of #858BA9, changed to color */
  color: #858ba9; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}

._a89a8c {
  align-items: center;
  background-color: #65654f; /* Darker shade of #A89A8C, changed to color */
  color: #a89a8c; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
}

._808a7c {
  align-items: center;
  background-color: #595943; /* Darker shade of #808A7C, changed to color */
  color: #808a7c; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}

._9f7f75 {
  align-items: center;
  background-color: #6b5e59; /* Darker shade of #9F7F75, changed to color */
  color: #9f7f75; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}

._827d89 {
  align-items: center;
  background-color: #595964; /* Darker shade of #827D89, changed to color */
  color: #827d89; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}

._7d8585 {
  align-items: center;
  background-color: #595964; /* Darker shade of #7D8585, changed to color */
  color: #7d8585; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}

._a97d75 {
  align-items: center;
  background-color: #635959; /* Darker shade of #A97D75, changed to color */
  color: #a97d75; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}

._828ca0 {
  align-items: center;
  background-color: #5e5e82; /* Darker shade of #828CA0, changed to color */
  color: #828ca0; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.icon-item {
  width: 100%;
}

.show-tooltip {
  cursor: pointer;
}

.show-tooltip:hover::before {
  content: attr(title);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px;
  border-radius: 4px;
  z-index: 1000;
  display: block;
  width: max-content;
}

@keyframes blinkTextCursor {
  from {
    border-right-color: hsl(0, 0%, 80%);
  }
  to {
    border-right-color: transparent;
  }
}

.confirmNotificationButton {
  width: auto;
  color: #48768c;
  font-weight: 600;
  font-size: 12.5px;
  border: 0.75px solid #48768c;
  padding: 6px;
  border-radius: 6px;
  background-color: white;
  transition: box-shadow 0.3s ease; /* Adds smooth transition */
  margin-left: auto;
  margin-top: -2rem;

  /* Box shadow for subtle effect */
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

.setupLaterButton:hover {
  /* Slightly stronger shadow on hover */
  box-shadow:
    0 8px 12px rgba(0, 0, 0, 0.15),
    0 3px 6px rgba(0, 0, 0, 0.12);
}
