.frame {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  min-height: 763px;
  min-width: 1430px;
  position: relative;
  width: 100%;
  box-shadow: 0px 15px 8px rgba(0, 0, 0, 0.1); /* Drop shadow */
}

.frame .closed-for-beta {
  background-color: #e1e7ef;
  border-radius: 20px;
  height: 763px;
  overflow: hidden;
  position: relative;
  width: 1430px;
}

.frame .div {
  align-items: flex-start;
  display: inline-flex;
  gap: 30px;
  justify-content: center;
  left: 419px;
  position: absolute;
  top: 735px;
}

.frame .text-wrapper {
  color: var(--x-333333);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  height: 28px;
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  margin-top: -1px;
  position: relative;
  width: 247px;
}

.frame .text-wrapper-2 {
  color: var(--x-333333);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.frame .inbetacell {
  height: 221px;
  left: 826px;
  position: absolute;
  top: 278px;
  width: 481px;
}

.frame .logo {
  height: 90px;
  left: 15px;
  position: absolute;
  top: 30px;
  width: 151px;
}

.frame .heading {
  color: var(--x-006-6cc);
  font-family: var(--heading-h2-font-family);
  font-size: var(--heading-h2-font-size);
  font-style: var(--heading-h2-font-style);
  font-weight: var(--heading-h2-font-weight);
  left: 38px;
  letter-spacing: var(--heading-h2-letter-spacing);
  line-height: var(--heading-h2-line-height);
  position: absolute;
  top: 152px;
  width: 504px;
}

.frame .body-text {
  color: var(--x-333333);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  left: 38px;
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: absolute;
  top: 277px;
  width: 616px;
}

.frame .tagline {
  color: var(--black);
  font-family: var(--text-regular-medium-font-family);
  font-size: var(--text-regular-medium-font-size);
  font-style: var(--text-regular-medium-font-style);
  font-weight: var(--text-regular-medium-font-weight);
  left: 88px;
  letter-spacing: var(--text-regular-medium-letter-spacing);
  line-height: var(--text-regular-medium-line-height);
  position: absolute;
  top: 412px;
  width: 624px;
}

.frame .p {
  color: var(--x-333333);
  font-family: var(--text-regular-medium-font-family);
  font-size: var(--text-regular-medium-font-size);
  font-style: var(--text-regular-medium-font-style);
  font-weight: var(--text-regular-medium-font-weight);
  left: 89px;
  letter-spacing: var(--text-regular-medium-letter-spacing);
  line-height: var(--text-regular-medium-line-height);
  position: absolute;
  top: 471px;
  white-space: nowrap;
  width: 541px;
}

.frame .tagline-2 {
  color: var(--x-333333);
  font-family: var(--text-regular-medium-font-family);
  font-size: var(--text-regular-medium-font-size);
  font-style: var(--text-regular-medium-font-style);
  font-weight: var(--text-regular-medium-font-weight);
  left: 88px;
  letter-spacing: var(--text-regular-medium-letter-spacing);
  line-height: var(--text-regular-medium-line-height);
  position: absolute;
  top: 504px;
  width: 618px;
}

.frame .closedfor-beta {
  color: #00cc99ed;
  font-family: "Roboto-ExtraBold", Helvetica;
  font-size: 16px;
  font-weight: 800;
  height: 24px;
  left: 103px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 599px;
  white-space: nowrap;
}

.frame .sign-up-box {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: #000000;
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: inline-flex;
  gap: 8px;
  left: 492px;
  padding: 24px;
  position: absolute;
  top: 645px;
  width: 389.98px;
}

.frame .sign-up-button {
  all: unset;
  align-items: center;
  background-color: var(--x-0-0cc-99);
  border: 1px solid;
  border-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0px 4px 4px #00000040;
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  justify-content: center;
  left: 896px;
  padding: 12px 24px;
  position: absolute;
  top: 645px;
  width: 103px;
}

.frame .button {
  all: unset;
  box-sizing: border-box;
  color: var(--white);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-left: -0.79px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .divider {
  background-color: var(--x-5c-5c-5c);
  border: 1px solid;
  border-color: #000000;
  box-shadow: 0px 4px 4px #00000040;
  height: 1px;
  left: 69px;
  position: absolute;
  top: 717px;
  width: 1292px;
}

.frame .request-access {
  align-items: center;
  background-color: var(--x-0-0cc-99);
  border: 1px solid;
  border-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0px 4px 4px #00000040;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  left: 1067px;
  padding: 12px 24px;
  position: absolute;
  top: 52px;
}

.frame .text-wrapper-3 {
  -webkit-text-stroke: 1px transparent;
  color: var(--white);
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  margin-left: -1px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .login-button {
  all: unset;
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0px 4px 4px #00000040;
  box-sizing: border-box;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  left: 193px;
  padding: 12px 24px;
  position: absolute;
  top: -1px;
}

.frame .text-wrapper-4 {
  -webkit-text-stroke: 1px transparent;
  color: var(--x-0-0cc-99);
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  margin-left: -1px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

:root {
  --b-4bfcc: rgba(180, 191, 204, 1);
  --black: rgba(0, 0, 0, 1);
  --ccf-6ff: rgba(204, 246, 255, 1);
  --dce-8f-5: rgba(220, 232, 245, 1);
  --e-6f-2ff: rgba(230, 242, 255, 1);
  --heading-h1-font-family: "Roboto-Bold", Helvetica;
  --heading-h1-font-size: 56px;
  --heading-h1-font-style: normal;
  --heading-h1-font-weight: 700;
  --heading-h1-letter-spacing: 0px;
  --heading-h1-line-height: 120.00000476837158%;
  --heading-h1-mobile-font-family: "Roboto-Bold", Helvetica;
  --heading-h1-mobile-font-size: 40px;
  --heading-h1-mobile-font-style: normal;
  --heading-h1-mobile-font-weight: 700;
  --heading-h1-mobile-letter-spacing: 0px;
  --heading-h1-mobile-line-height: 120.00000476837158%;
  --heading-h2-font-family: "Roboto-Bold", Helvetica;
  --heading-h2-font-size: 48px;
  --heading-h2-font-style: normal;
  --heading-h2-font-weight: 700;
  --heading-h2-letter-spacing: 0px;
  --heading-h2-line-height: 120.00000476837158%;
  --heading-h2-mobile-font-family: "Roboto-Bold", Helvetica;
  --heading-h2-mobile-font-size: 36px;
  --heading-h2-mobile-font-style: normal;
  --heading-h2-mobile-font-weight: 700;
  --heading-h2-mobile-letter-spacing: 0px;
  --heading-h2-mobile-line-height: 120.00000476837158%;
  --heading-h3-font-family: "Roboto-Bold", Helvetica;
  --heading-h3-font-size: 40px;
  --heading-h3-font-style: normal;
  --heading-h3-font-weight: 700;
  --heading-h3-letter-spacing: 0px;
  --heading-h3-line-height: 120.00000476837158%;
  --heading-h3-mobile-font-family: "Roboto-Bold", Helvetica;
  --heading-h3-mobile-font-size: 32px;
  --heading-h3-mobile-font-style: normal;
  --heading-h3-mobile-font-weight: 700;
  --heading-h3-mobile-letter-spacing: 0px;
  --heading-h3-mobile-line-height: 120.00000476837158%;
  --heading-h4-font-family: "Roboto-Bold", Helvetica;
  --heading-h4-font-size: 32px;
  --heading-h4-font-style: normal;
  --heading-h4-font-weight: 700;
  --heading-h4-letter-spacing: 0px;
  --heading-h4-line-height: 129.99999523162842%;
  --heading-h4-mobile-font-family: "Roboto-Bold", Helvetica;
  --heading-h4-mobile-font-size: 24px;
  --heading-h4-mobile-font-style: normal;
  --heading-h4-mobile-font-weight: 700;
  --heading-h4-mobile-letter-spacing: 0px;
  --heading-h4-mobile-line-height: 139.9999976158142%;
  --heading-h5-font-family: "Roboto-Bold", Helvetica;
  --heading-h5-font-size: 24px;
  --heading-h5-font-style: normal;
  --heading-h5-font-weight: 700;
  --heading-h5-letter-spacing: 0px;
  --heading-h5-line-height: 139.9999976158142%;
  --heading-h5-mobile-font-family: "Roboto-Bold", Helvetica;
  --heading-h5-mobile-font-size: 20px;
  --heading-h5-mobile-font-style: normal;
  --heading-h5-mobile-font-weight: 700;
  --heading-h5-mobile-letter-spacing: 0px;
  --heading-h5-mobile-line-height: 139.9999976158142%;
  --heading-h6-font-family: "Roboto-Bold", Helvetica;
  --heading-h6-font-size: 20px;
  --heading-h6-font-style: normal;
  --heading-h6-font-weight: 700;
  --heading-h6-letter-spacing: 0px;
  --heading-h6-line-height: 139.9999976158142%;
  --heading-h6-mobile-font-family: "Roboto-Bold", Helvetica;
  --heading-h6-mobile-font-size: 18px;
  --heading-h6-mobile-font-style: normal;
  --heading-h6-mobile-font-weight: 700;
  --heading-h6-mobile-letter-spacing: 0px;
  --heading-h6-mobile-line-height: 139.9999976158142%;
  --large: 0px 8px 8px -4px rgba(0, 0, 0, 0.03),
    0px 20px 24px -4px rgba(0, 0, 0, 0.08);
  --light-grey: rgba(244, 244, 244, 1);
  --medium: 0px 4px 6px -2px rgba(0, 0, 0, 0.03),
    0px 12px 16px -4px rgba(0, 0, 0, 0.08);
  --small: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  --text-large-bold-font-family: "Roboto-Bold", Helvetica;
  --text-large-bold-font-size: 20px;
  --text-large-bold-font-style: normal;
  --text-large-bold-font-weight: 700;
  --text-large-bold-letter-spacing: 0px;
  --text-large-bold-line-height: 150%;
  --text-large-extra-bold-font-family: "Roboto-ExtraBold", Helvetica;
  --text-large-extra-bold-font-size: 20px;
  --text-large-extra-bold-font-style: normal;
  --text-large-extra-bold-font-weight: 800;
  --text-large-extra-bold-letter-spacing: 0px;
  --text-large-extra-bold-line-height: 150%;
  --text-large-light-font-family: "Roboto-Light", Helvetica;
  --text-large-light-font-size: 20px;
  --text-large-light-font-style: normal;
  --text-large-light-font-weight: 300;
  --text-large-light-letter-spacing: 0px;
  --text-large-light-line-height: 150%;
  --text-large-link-font-family: "Roboto-Regular", Helvetica;
  --text-large-link-font-size: 20px;
  --text-large-link-font-style: normal;
  --text-large-link-font-weight: 400;
  --text-large-link-letter-spacing: 0px;
  --text-large-link-line-height: 150%;
  --text-large-medium-font-family: "Roboto-Medium", Helvetica;
  --text-large-medium-font-size: 20px;
  --text-large-medium-font-style: normal;
  --text-large-medium-font-weight: 500;
  --text-large-medium-letter-spacing: 0px;
  --text-large-medium-line-height: 150%;
  --text-large-normal-font-family: "Roboto-Regular", Helvetica;
  --text-large-normal-font-size: 20px;
  --text-large-normal-font-style: normal;
  --text-large-normal-font-weight: 400;
  --text-large-normal-letter-spacing: 0px;
  --text-large-normal-line-height: 150%;
  --text-large-semi-bold-font-family: "Roboto-SemiBold", Helvetica;
  --text-large-semi-bold-font-size: 20px;
  --text-large-semi-bold-font-style: normal;
  --text-large-semi-bold-font-weight: 600;
  --text-large-semi-bold-letter-spacing: 0px;
  --text-large-semi-bold-line-height: 150%;
  --text-medium-bold-font-family: "Roboto-Bold", Helvetica;
  --text-medium-bold-font-size: 18px;
  --text-medium-bold-font-style: normal;
  --text-medium-bold-font-weight: 700;
  --text-medium-bold-letter-spacing: 0px;
  --text-medium-bold-line-height: 150%;
  --text-medium-extra-bold-font-family: "Roboto-Bold", Helvetica;
  --text-medium-extra-bold-font-size: 18px;
  --text-medium-extra-bold-font-style: normal;
  --text-medium-extra-bold-font-weight: 700;
  --text-medium-extra-bold-letter-spacing: 0px;
  --text-medium-extra-bold-line-height: 150%;
  --text-medium-light-font-family: "Roboto-Light", Helvetica;
  --text-medium-light-font-size: 18px;
  --text-medium-light-font-style: normal;
  --text-medium-light-font-weight: 300;
  --text-medium-light-letter-spacing: 0px;
  --text-medium-light-line-height: 150%;
  --text-medium-link-font-family: "Roboto-Regular", Helvetica;
  --text-medium-link-font-size: 18px;
  --text-medium-link-font-style: normal;
  --text-medium-link-font-weight: 400;
  --text-medium-link-letter-spacing: 0px;
  --text-medium-link-line-height: 150%;
  --text-medium-medium-font-family: "Roboto-Medium", Helvetica;
  --text-medium-medium-font-size: 18px;
  --text-medium-medium-font-style: normal;
  --text-medium-medium-font-weight: 500;
  --text-medium-medium-letter-spacing: 0px;
  --text-medium-medium-line-height: 150%;
  --text-medium-normal-font-family: "Roboto-Regular", Helvetica;
  --text-medium-normal-font-size: 18px;
  --text-medium-normal-font-style: normal;
  --text-medium-normal-font-weight: 400;
  --text-medium-normal-letter-spacing: 0px;
  --text-medium-normal-line-height: 150%;
  --text-medium-semi-bold-font-family: "Roboto-SemiBold", Helvetica;
  --text-medium-semi-bold-font-size: 18px;
  --text-medium-semi-bold-font-style: normal;
  --text-medium-semi-bold-font-weight: 600;
  --text-medium-semi-bold-letter-spacing: 0px;
  --text-medium-semi-bold-line-height: 150%;
  --text-regular-bold-font-family: "Roboto-Bold", Helvetica;
  --text-regular-bold-font-size: 16px;
  --text-regular-bold-font-style: normal;
  --text-regular-bold-font-weight: 700;
  --text-regular-bold-letter-spacing: 0px;
  --text-regular-bold-line-height: 150%;
  --text-regular-extra-bold-font-family: "Roboto-ExtraBold", Helvetica;
  --text-regular-extra-bold-font-size: 16px;
  --text-regular-extra-bold-font-style: normal;
  --text-regular-extra-bold-font-weight: 800;
  --text-regular-extra-bold-letter-spacing: 0px;
  --text-regular-extra-bold-line-height: 150%;
  --text-regular-light-font-family: "Roboto-Light", Helvetica;
  --text-regular-light-font-size: 16px;
  --text-regular-light-font-style: normal;
  --text-regular-light-font-weight: 300;
  --text-regular-light-letter-spacing: 0px;
  --text-regular-light-line-height: 150%;
  --text-regular-link-font-family: "Roboto-Regular", Helvetica;
  --text-regular-link-font-size: 16px;
  --text-regular-link-font-style: normal;
  --text-regular-link-font-weight: 400;
  --text-regular-link-letter-spacing: 0px;
  --text-regular-link-line-height: 150%;
  --text-regular-medium-font-family: "Roboto-SemiBold", Helvetica;
  --text-regular-medium-font-size: 16px;
  --text-regular-medium-font-style: normal;
  --text-regular-medium-font-weight: 600;
  --text-regular-medium-letter-spacing: 0px;
  --text-regular-medium-line-height: 150%;
  --text-regular-normal-font-family: "Roboto-Regular", Helvetica;
  --text-regular-normal-font-size: 16px;
  --text-regular-normal-font-style: normal;
  --text-regular-normal-font-weight: 400;
  --text-regular-normal-letter-spacing: 0px;
  --text-regular-normal-line-height: 150%;
  --text-regular-semi-bold-font-family: "Roboto-SemiBold", Helvetica;
  --text-regular-semi-bold-font-size: 16px;
  --text-regular-semi-bold-font-style: normal;
  --text-regular-semi-bold-font-weight: 600;
  --text-regular-semi-bold-letter-spacing: 0.32px;
  --text-regular-semi-bold-line-height: 150%;
  --text-small-bold-font-family: "Roboto-Bold", Helvetica;
  --text-small-bold-font-size: 14px;
  --text-small-bold-font-style: normal;
  --text-small-bold-font-weight: 700;
  --text-small-bold-letter-spacing: 0px;
  --text-small-bold-line-height: 150%;
  --text-small-extra-bold-font-family: "Roboto-ExtraBold", Helvetica;
  --text-small-extra-bold-font-size: 14px;
  --text-small-extra-bold-font-style: normal;
  --text-small-extra-bold-font-weight: 800;
  --text-small-extra-bold-letter-spacing: 0px;
  --text-small-extra-bold-line-height: 150%;
  --text-small-light-font-family: "Roboto-Light", Helvetica;
  --text-small-light-font-size: 14px;
  --text-small-light-font-style: normal;
  --text-small-light-font-weight: 300;
  --text-small-light-letter-spacing: 0px;
  --text-small-light-line-height: 150%;
  --text-small-link-font-family: "Roboto-Regular", Helvetica;
  --text-small-link-font-size: 14px;
  --text-small-link-font-style: normal;
  --text-small-link-font-weight: 400;
  --text-small-link-letter-spacing: 0px;
  --text-small-link-line-height: 150%;
  --text-small-medium-font-family: "Roboto-Medium", Helvetica;
  --text-small-medium-font-size: 14px;
  --text-small-medium-font-style: normal;
  --text-small-medium-font-weight: 500;
  --text-small-medium-letter-spacing: 0px;
  --text-small-medium-line-height: 150%;
  --text-small-normal-font-family: "Roboto-Regular", Helvetica;
  --text-small-normal-font-size: 14px;
  --text-small-normal-font-style: normal;
  --text-small-normal-font-weight: 400;
  --text-small-normal-letter-spacing: 0px;
  --text-small-normal-line-height: 150%;
  --text-small-semi-bold-font-family: "Roboto-SemiBold", Helvetica;
  --text-small-semi-bold-font-size: 14px;
  --text-small-semi-bold-font-style: normal;
  --text-small-semi-bold-font-weight: 600;
  --text-small-semi-bold-letter-spacing: 0px;
  --text-small-semi-bold-line-height: 150%;
  --text-tiny-bold-font-family: "Roboto-Bold", Helvetica;
  --text-tiny-bold-font-size: 12px;
  --text-tiny-bold-font-style: normal;
  --text-tiny-bold-font-weight: 700;
  --text-tiny-bold-letter-spacing: 0px;
  --text-tiny-bold-line-height: 150%;
  --text-tiny-extra-bold-font-family: "Roboto-ExtraBold", Helvetica;
  --text-tiny-extra-bold-font-size: 12px;
  --text-tiny-extra-bold-font-style: normal;
  --text-tiny-extra-bold-font-weight: 800;
  --text-tiny-extra-bold-letter-spacing: 0px;
  --text-tiny-extra-bold-line-height: 150%;
  --text-tiny-light-font-family: "Roboto-Light", Helvetica;
  --text-tiny-light-font-size: 12px;
  --text-tiny-light-font-style: normal;
  --text-tiny-light-font-weight: 300;
  --text-tiny-light-letter-spacing: 0px;
  --text-tiny-light-line-height: 150%;
  --text-tiny-link-font-family: "Roboto-Regular", Helvetica;
  --text-tiny-link-font-size: 12px;
  --text-tiny-link-font-style: normal;
  --text-tiny-link-font-weight: 400;
  --text-tiny-link-letter-spacing: 0px;
  --text-tiny-link-line-height: 150%;
  --text-tiny-medium-font-family: "Roboto-Medium", Helvetica;
  --text-tiny-medium-font-size: 12px;
  --text-tiny-medium-font-style: normal;
  --text-tiny-medium-font-weight: 500;
  --text-tiny-medium-letter-spacing: 0px;
  --text-tiny-medium-line-height: 150%;
  --text-tiny-normal-font-family: "Roboto-Regular", Helvetica;
  --text-tiny-normal-font-size: 12px;
  --text-tiny-normal-font-style: normal;
  --text-tiny-normal-font-weight: 400;
  --text-tiny-normal-letter-spacing: 0px;
  --text-tiny-normal-line-height: 150%;
  --text-tiny-semi-bold-font-family: "Roboto-SemiBold", Helvetica;
  --text-tiny-semi-bold-font-size: 12px;
  --text-tiny-semi-bold-font-style: normal;
  --text-tiny-semi-bold-font-weight: 600;
  --text-tiny-semi-bold-letter-spacing: 0px;
  --text-tiny-semi-bold-line-height: 150%;
  --variable-collection-0066cc: rgba(255, 255, 255, 1);
  --variable-collection-primary-200: rgba(92, 147, 255, 1);
  --white: rgba(255, 255, 255, 1);
  --x-0-0cc-99: rgba(0, 204, 153, 1);
  --x-00-6a-40: rgba(0, 106, 64, 1);
  --x-006-6cc: rgba(0, 102, 204, 1);
  --x-333333: rgba(51, 51, 51, 1);
  --x-5c-5c-5c: rgba(92, 92, 92, 1);
  --x-5c-9-3ff: rgba(92, 147, 255, 1);
  --xlarge: 0px 24px 48px -12px rgba(0, 0, 0, 0.18);
  --xsmall: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --xxlarge: 0px 32px 64px -12px rgba(0, 0, 0, 0.14);
  --xxsmall: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
