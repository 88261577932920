.toggle_option_container {
  align-items: center;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.6rem;
}

.last_toggle_option_container {
  align-items: center;
  margin-bottom: 0.8rem;
  padding-bottom: 0.2rem;
}
